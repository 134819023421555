/*----------------------------------------*/
/*  05. Header CSS
/*----------------------------------------*/

@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

/* top header */
.small__top__header {
  background-color: $header-top-color;
  color: $white;
  z-index: 99999;
  position: relative;
  padding: 0 15px;
  @media #{$large-device} {
    display: none;
  }



  .login__sign__form {
    display: flex;
    float: right;
    padding: 10px 0px;
    font-weight: 900;
    letter-spacing: 2px;
    margin-right: 10px;
    cursor: pointer;

    .login {
      font-size: small;
    }
    .signup {
      font-size: small;
      &::before {
        content: "|";
        margin: 0 10px;
      }
    }

    &:hover {
      color: $white;
    }

    &:active {
      color: $white;
    }

    &:focus {
      color: $white;
    }


    .dropdown__login {
      .dropdown-menu {
        z-index: 99999;
      }
    }

    .custom_button_right{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
  }

  .inner-language__dropdown {
    font-weight: 600;

    .dropdown-menu {
      z-index: 99999;
    }
  }

  .dropdown__language {
    .select__inner {
      width: 200px;
      z-index: 99999;
    }
  }

  .top_header_right {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media #{$large-device} {
      justify-content: center;
    }

    .notify_btn {
      color: $white !important;
      border-radius: 5px;
      height: 38px;
      position: relative;
    }

    .right_guest_profile{
      display: flex;
      align-items: center;
      .profile_image{
        width: 35px;
        height: 35px;
        object-fit: cover;
        margin-right: 10px;
        border-radius: 50%;
        color: $white !important;
        background: $themecolor!important;
        box-shadow: inset -4px -4px 8px $gradient-two, inset 8px 8px 16px $gradient-one!important;
        transition: 0.5s ease-in;
        border-color: transparent;
        padding:1px;
      }

      .guest_login{
        font-size: 14px;
        font-weight: 600;
      }
    }

  }

  .dropdown__language {
    @media #{$large-device} {
      text-align: center;
    }
  }

  .notification{
    .notification_badges{
      position: absolute;
      right: 6px;
      top: 2px;
      background: $mate-black;
      border-radius: 50%;
      width: 17px;
      font-size: 10px;
    }
  }
}

.small__top__header .login__sign__form .btn-check:active + .btn-primary,
.btn-check:checked + .btn-primary,
.btn-primary.active,
.btn-primary:active,
.show > .btn-primary.dropdown-toggle {
  color: $white !important;
  background: $themecolor;
  box-shadow: inset -4px -4px 8px $gradient-two, inset 8px 8px 16px $gradient-one;;
  transition: 0.5s ease-in;
  border-color: transparent;
  // box-shadow: none !important;
}

/* Header Section */

.header-section {
  min-height: 100px;
  z-index: 999;
  position: relative;

  // Responsive
  @media #{$tablet-device} {
    min-height: 80px;
  }
  @media #{$large-device} {
    min-height: 80px;
    margin-top: 95px;
  }

  & .header-inner {
    display: flex;
    align-items: center;

    min-height: 80px;
    // Responsive
    @media #{$tablet-device} {
      min-height: 80px;
    }
    @media #{$large-device} {
      // min-height: 100px;
    }
  }
  &.is-sticky {
    & .header-inner {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 105px;
      background-color: #fff !important;
      animation: headerSlideDown 0.95s ease forwards;
      box-shadow: 0 8px 20px 0 rgba($mate-black, 0.1);
    }
  }

  // Header Fluid
  &.header-fluid {
    & .container {
      max-width: 100%;

      @media #{$extraBig-device} {
        padding-right: 150px;
        padding-left: 150px;
      }
    }
  }
  &.header-fluid-two {
    & .container {
      max-width: 100%;

      @media #{$extraBig-device} {
        padding-right: 240px;
        padding-left: 240px;
      }
    }
  }

  // Header Shadow
  &.header-shadow {
    & .header-inner {
      box-shadow: 0 3px 9px rgba(0, 0, 0, 0.05);
    }
  }

  // Header Transparent
  &.header-transparent {
    @media #{$large-device} {
      margin-top: 0;
      // display: contents;
    }

    & .header-inner {
      background-color: transparent;
      z-index: 10;
    }
  }

  // Header Light
  &.header-light {
    & .header-login {
      & a {
        color: $white;

        &:hover {
          color: $white;
        }
      }
    }
  }


}

@keyframes headerSlideDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

/* 05.04 Header Logo CSS */
.header-logo {
  // width: 70px;
  // height: 70px;

  a {
    position: relative;
    max-width: 158px;

    @media #{$extra-small-device} {
      width: 40px;
      height: 40px;
      display: block;
      
    }



    img {

      @media #{$extra-small-device} {
        height: 100px;
        object-fit: contain;
      }

      &.light-logo {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;
      }

      &.dark-logo {
        opacity: 1;
        width: 80%;
        height: 80%;
        object-fit: contain;
      }
    }
  }
}
// }

.profileImage {
  width: 40px;
  height: 40px;
  background: transparent;
  border-radius: 100%;
   float: inline-end;
  border: 2px solid #000000;
  padding: 3px;
  // margin-left: 280px;
  
}

.profile{
  width: 100%;
    height: 100%;
    object-fit: cover;
}

@-webkit-keyframes headerSlideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes headerSlideDown {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

/* 05.01 Main Menu CSS */
.site-main-menu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
    padding-left: 0;
    list-style: none;

    > li {
      position: relative;

      > a {
        // font-size: 16px;
        font-weight: 500;
        line-height: 1.375;
        display: block;
        padding: 10px 15px;
        border-radius: 10px;
        color: #666666;

        .menu-text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          letter-spacing: 1px;
          text-align: left;
          font-family: "Lato", sans-serif;
          text-transform: capitalize;
        }
      }

      .menu-toggle {
        display: none;
      }
      &.has-children {
        > a {
          .menu-text {
            &::after {
              font-family: "Font Awesome 5 Free";
              content: "\f107";
              font-size: 8px;
              font-weight: 900;
              margin-left: 10px;
            }
          }
        }
      }

      &:hover {
        > .sub-menu {
          visibility: visible;
          margin-top: 10px;
          opacity: 1;
        }

        .mega-menu {
          visibility: visible;
          margin-top: 10px;
          opacity: 1;
        }
      }
    }
  }

  & .sub-menu,
  .mega-menu {
    position: absolute;
    z-index: 9999;
    top: 100%;
    right: 0;
    visibility: hidden;
    width: 240px;
    margin-top: 20px;
    margin-bottom: 0;
    padding: 1px 0;
    list-style: none;
    transition: all 0.4s ease 0.2s;
    opacity: 0;
    border-radius: 10px;
    background-color: $white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 29px rgba($black, 0.05);

    &::before {
      content: "";
      position: absolute;
      top: -10px;
      right: 40px;
      text-align: center;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 7.5px 10px 7.5px;
      border-color: transparent transparent $themecolor transparent;
    }
    & li {
      position: relative;

      & a {
        font-size: 16px;
        font-weight: 400;
        line-height: 1.375;
        border-radius: 10px;
        display: block;
        padding: 8px 30px;
        white-space: nowrap;
        color: $black;

        & .menu-text {
          display: flex;
          align-items: center;
        }
      }

      & .menu-toggle {
        display: none;
      }

      &.has-children {
        & > a {
          & .menu-text {
            &::after {
              font-family: "Font Awesome 5 Free";
              content: "\f107";
              font-size: 8px;
              font-weight: 900;

              margin-left: auto;
            }
          }
        }
      }

      &:hover,
      &.active {
        & > a {
          color: #111111;
        }
      }

      &:hover {
        & > .sub-menu {
          visibility: visible;

          margin-top: 0px;

          opacity: 1;
        }
      }
    }

    & .sub-menu {
      top: -18px;
      left: 100%;
      &::before {
        display: none;
      }
    }
  }

  & .mega-menu {
    left: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding: 30px 15px 34px;
    list-style: none;
    & > li {
      flex: 0 0 25%;
      width: 25%;
      padding: 0 15px;
      &.menu-item-50 {
        flex: 0 0 50%;
        width: 50%;
        padding: 0 15px;
      }
      & > ul {
        padding-left: 0;
        list-style: none;
        margin: 0;
        & > li {
          & > a {
            font-size: 15px;
            line-height: 1.78;
            font-weight: 500;
            padding: 8px 0px;
            .badge {
              background-color: transparent;
              background-image: linear-gradient(45deg, #fe378c 0%, #fe5b34 100%);
              display: inline-block;
              border-radius: 2px;
              padding: 4px 8px 3px;
              color: $white;
              font-size: 11px;
              line-height: 1;
              font-weight: 700;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              margin: 0 0 0 7px;
              &.primary {
                background-image: none;
                // background-color: $primary !important;
              }
            }
          }
        }
      }
    }
  }

  .has-children {
    ul {
      li {
        &:hover {
          a {
            span {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

.site-main-menu {
  ul {
    li {
      span {
        &::before {
          display: none;
        }
      }

      span {
        margin-right: 0;
        &:hover {
          transition: 10ms ease-in;
        }
      }

      &:hover {
        &:nth-child(1) {
          span {
            &::before {
              font-family: "Font Awesome 5 Free";
              content: "\f015";
              margin-right: 3px;
              display: flex;
              color: $themecolor;
              animation: slide 0.3s ease-in;
            }
          }
        }
        &:nth-child(2) {
          span {
            &::before {
              font-family: "Font Awesome 5 Free";
              content: "\f144";
              margin-right: 3px;
              display: block;
              color: $themecolor;
              animation: slide 0.3s ease-in;
            }
          }
        }

        &:nth-child(3) {
          span {
            &::before {
              font-family: "Font Awesome 5 Free";

              content: "\f500";
              margin-right: 3px;
              display: block;
              color: $themecolor;
              animation: slide 0.3s ease-in;
            }
          }
        }

        &:nth-child(4) {
          span {
            &::before {
              font-family: "Font Awesome 5 Free";

              content: "\f022";
              margin-right: 3px;
              display: block;
              color: $themecolor;
              animation: slide 0.3s ease-in;
            }
          }
        }

        &:nth-child(5) {
          span {
            &::before {
              font-family: "Font Awesome 5 Free";
              content: "\f091";
              margin-right: 3px;
              display: block;
              color: $themecolor;
              animation: slide 0.3s ease-in;
            }
          }
        }
      }

      .navbar__link--active {
        span {
          &::before {
            display: none;
          }
        }

        span {
          margin-right: 0;
          &:hover {
            transition: 10ms ease-in;
          }
        }

        &:hover {
          &:nth-child(1) {
            span {
              &::before {
                color: $white;
              }
            }
          }
          &:nth-child(2) {
            span {
              &::before {
                color: $white;
              }
            }
          }

          &:nth-child(3) {
            span {
              &::before {
                color: $white;
              }
            }
          }

          &:nth-child(4) {
            span {
              &::before {
                color: $white;
              }
            }
          }

          &:nth-child(5) {
            span {
              &::before {
                color: $white;
              }
            }
          }

          &:nth-child(6) {
            span {
              &::before {
                color: $white;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slide {
  0% {
    transform: translateX(35%);
  }

  100% {
    transform: translateX(0%);
  }
}

/*  Mobile Menu Toggle CSS */
.header-mobile-menu-toggle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;

  .toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    border: none;
    background-color: transparent;

    i {
      position: relative;
      display: block;
      overflow: hidden;
      width: 24px;
      height: 3px;

      + i {
        margin-top: 6px;
      }
    }
  }
}

.mobile-menu-close {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0 10px;

  .toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 0;
    border: none;
    background-color: transparent;

    i {
      position: relative;
      display: block;
      overflow: hidden;
      width: 24px;
      height: 2px;

      + i {
        margin-top: 6px;
      }
    }
  }
}

.header-mobile-menu-toggle .toggle i::before,
.mobile-menu-close .toggle i::before,
.header-mobile-menu-toggle .toggle i::after,
.mobile-menu-close .toggle i::after {
  @include header-toggle-before-after;
}

.header-mobile-menu-toggle .toggle i.icon-top::before,
.mobile-menu-close .toggle i.icon-top::before,
.header-mobile-menu-toggle .toggle i.icon-bottom::before,
.mobile-menu-close .toggle i.icon-bottom::before {
  @include header-toggle-icon-before-after;
}

.header-mobile-menu-toggle .toggle i.icon-top::after,
.mobile-menu-close .toggle i.icon-top::after,
.header-mobile-menu-toggle .toggle i.icon-bottom::after,
.mobile-menu-close .toggle i.icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
  transform-origin: left;
}

.header-mobile-menu-toggle .toggle i.icon-middle::before,
.mobile-menu-close .toggle i.icon-middle::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
  transform-origin: left;
}

.header-mobile-menu-toggle .toggle i.icon-middle::after,
.mobile-menu-close .toggle i.icon-middle::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
  transform-origin: right;
}

.header-mobile-menu-toggle .toggle:hover i::before,
.mobile-menu-close .toggle:hover i::before,
.header-mobile-menu-toggle .toggle:hover i::after,
.mobile-menu-close .toggle:hover i::after {
  background-color: $themecolor;
}

.header-mobile-menu-toggle .toggle:hover i.icon-top::before,
.mobile-menu-close .toggle:hover i.icon-top::before,
.header-mobile-menu-toggle .toggle:hover i.icon-bottom::before,
.mobile-menu-close .toggle:hover i.icon-bottom::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
}

.header-mobile-menu-toggle .toggle:hover i.icon-top::after,
.mobile-menu-close .toggle:hover i.icon-top::after,
.header-mobile-menu-toggle .toggle:hover i.icon-bottom::after,
.mobile-menu-close .toggle:hover i.icon-bottom::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
}

.header-mobile-menu-toggle .toggle:hover i.icon-middle::before,
.mobile-menu-close .toggle:hover i.icon-middle::before {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transform: scaleX(0) translateZ(0);
}

.header-mobile-menu-toggle .toggle:hover i.icon-middle::after,
.mobile-menu-close .toggle:hover i.icon-middle::after {
  transition: transform 0.6s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
  transform: scaleX(1) translateZ(0);
}

@media #{$extra-small-device} {
  .header-mobile-menu-toggle,
  .mobile-menu-close {
    padding-right: 8px;
    padding-left: 8px;
  }
}

.header-light .header-mobile-menu-toggle .toggle i::before,
.header-light .mobile-menu-close .toggle i::before,
.header-light .header-mobile-menu-toggle .toggle i::after,
.header-light .mobile-menu-close .toggle i::after {
  background-color: $white;
}

.header-light .header-mobile-menu-toggle .toggle:hover i::before,
.header-light .mobile-menu-close .toggle:hover i::before,
.header-light .header-mobile-menu-toggle .toggle:hover i::after,
.header-light .mobile-menu-close .toggle:hover i::after {
  background-color: $white;
}

/* 05.03 Mobile Menu CSS */
.site-main-mobile-menu {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  visibility: hidden;
  width: 100%;
  height: 100%;
  @include common-transition;
  opacity: 0;
  background: rgba(0, 0, 0, 0.7);

  .site-main-mobile-menu-inner {
    position: relative;
    z-index: 9;
    float: right;
    width: 360px;
    height: 100%;
    @include common-transition;
    -webkit-transform: translateX(100%);
    -ms-transform: translateX(100%);
    transform: translateX(100%);
    background-color: #748494;
    background-repeat: no-repeat;
    background-attachment: scroll;
    background-position: top center;
    background-size: cover;

    @media #{$extra-small-device} {
      width: 300px;
    }

    &::before {
      z-index: -1;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: "";
      background-color: #212121;
    }
  }
}

#page {
  position: relative;
  @include common-transition;
}

.mobile-menu-open {
  overflow: hidden;

  #page {
    -webkit-transform: translateX(-360px);
    -ms-transform: translateX(-360px);
    transform: translateX(-360px);
  }

  .site-main-mobile-menu {
    visibility: visible;
    opacity: 1;

    .site-main-mobile-menu-inner {
      -webkit-transform: translateX(0);
      -ms-transform: translateX(0);
      transform: translateX(0);

      .mobile-menu-header {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 80px;
        padding: 0 15px 0 30px;
        background-color: #030f27;
      }

      .mobile-menu-close {
        padding: 0;
        

        .toggle {
          position: relative;
          width: 40px;
          height: 40px;

          i {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 29px;
            height: 2px;
            margin: 0 !important;

            transform-origin: center;

            &.icon-top {
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }

          &:hover {
            i {
              &::before {
                background-color: $white;
              }

              &::after {
                background-color: $white;
              }
            }
          }
        }
      }

      .mobile-menu-content {
        overflow-y: auto;
        height: calc(100% - 80px);
        padding: 20px 30px 100px;

        > ul {
          margin: 0;
          padding-left: 0;
          list-style: none;

          > li {
            position: relative !important;

            + li {
              > a {
                border-top: 1px solid rgba(255, 255, 255, 0.15);
              }
            }

            > a {
              font-size: 16px;
              font-weight: 500;
              line-height: 1.5;
              display: block;
              padding-top: 19px;
              padding-right: 0;
              padding-bottom: 19px;
              padding-left: 0;
              color: $white;
              border-bottom: 1px solid transparent;
            }
          }
        }
      }
    }
  }
}

.site-mobile-menu {
  > ul {
    > li {
      .menu-toggle {
        position: absolute;
        top: 11px;
        right: 0;
        @include flex-center;
        width: 40px;
        height: 40px;
        cursor: pointer;
        color: $white;
        border-radius: 50%;
        background-color: transparent;
        color: #000000;

        i {
          font-size: 18px;
          line-height: 1;
          @include common-transition;
        }

      }

      &.open {
        > a {
          border-bottom-color: rgba(255, 255, 255, 0.15);
        }

        > .menu-toggle {
          background-color: rgba(255, 255, 255, 0.2);

          i {
            -webkit-transform: rotateX(180deg);
            transform: rotateX(180deg);
          }
        }
      }
    }
  }
}

/* mobile toogle */
/* Mobile Menu CSS */
.site-main-mobile-menu {
  &.active {
    visibility: visible;
    opacity: 1;

    .site-main-mobile-menu-inner {
      transform: translateX(0);
    }
  }
}

.site-main-mobile-menu-inner {
  position: relative;
  z-index: 9;
  float: right;
  width: 360px;
  height: 100%;
  transform: translateX(100%);
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: top center;
  background-size: cover;

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
  }
}

.mobile-menu-open {
  .site-main-mobile-menu {
    visibility: visible;
    opacity: 1;
  }
  .site-main-mobile-menu-inner {
    transform: translateX(0);
  }
}

.mobile-menu-header {
  // display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  height: auto;
  margin-top: 20px;
}
.mobile-menu-logo {
  img {
    width: auto;
    object-fit: contain;
  }
}
.mobile-menu-close {
  padding: 0;

  .toggle {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 5px;
    right: 0px;
    
    i {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 29px;
      height: 2px;
      margin: 0 !important;
      transform-origin: center;

      &.icon-top {
        transform: translate(-50%, -50%) rotate(45deg);
      }

      &.icon-bottom {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }
  }
}

.mobile-menu-content {
  overflow-y: auto;
  height: calc(100% - 80px);
  padding: 20px 30px 100px;

  .right_guest_profile{
    margin-top: 10px;
    .profile_image{
      width: 40px;
      height: 40px;
      object-fit: cover;
      margin-right: 7px;
    }
  }
}
.site-mobile-menu {
  > ul {
    margin: 0;
    padding-left: 0;
    list-style: none;
    text-align: center;

    > li {
      position: relative !important;

      > a {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        display: block;
        padding-top: 19px;
        padding-right: 0;
        padding-bottom: 19px;
        padding-left: 0;
        border-bottom: 1px solid #ffffff1a;
        text-align: left;
      }
      a:hover {
        color: $themecolor !important;
      }

      .menu-toggle {
        position: absolute;
        top: 11px;
        right: 0;
        @include flex-center;
        width: 100%;
        justify-content: flex-end;
        height: 40px;
        cursor: pointer;
        border-radius: 50%;
        background-color: transparent;

        i {
          font-size: 18px;
          line-height: 1;
        }
      }

      &.open {
        > .menu-toggle {
          i {
            transform: rotateX(180deg);
          }
        }
      }
    }
  }
  .sub-menu,
  .mega-menu {
    display: none;
    margin: 14px 0;
    padding-left: 0;
    list-style: none;

    li {
      margin-top: 10px;
      text-align: left;
      a {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.5;
        padding-top: 10px;
        padding-right: 0;
        padding-bottom: 10px;
        padding-left: 0;
        color: rgba(255, 255, 255, 0.7);
      }
    }
  }
}

.custom_modal_notify {
  background-color: #fff;
  padding-bottom: 0;
  border-radius: 10px;
  .ant-modal-body {
    height: 350px;
    overflow-y: scroll;
    &::-webkit-scrollbar{
      width:5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb{
      background: $themecolor;
      border-radius: 15px;
    }
  }
  .ant-modal-header {
    border-bottom: 1px solid #f0f0f038;
    background-color: transparent;
  }

  .ant-modal-title {
    font-size: 1.4rem;
  }
}


.tp-menu-toggle {
  font-size: 16px;
  line-height: 0;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .tp-menu-toggle {
    margin-right: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .tp-menu-toggle {
    font-size: 28px;
  }
}

.tp-side-info-area {
  background: #171151;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding: 30px;
  width: 350px;
  transform: translateX(120%);
  transition: 0.3s;
  z-index: 99;
}
.tp-side-info-area.tp-sidebar-opened {
  transform: translateX(0);
}
@media (max-width: 767px) {
  .tp-side-info-area {
    width: 300px;
  }
}

.tp-sidebar-close {
  color: #fff;
  position: absolute;
  left: -35px;
  font-size: 21px;
  background: #171151;
  width: 35px;
  height: 35px;
}

.body-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999999;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-out 0s;
}
.body-overlay.opened {
  opacity: 1;
  visibility: visible;
}

.layout-left-right {
  display: flex;
  flex: 0 0 auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .layout-left-right {
    display: block;
  }
}

.tpsideinfo {
  background-color: #fff;
  text-align: start;
  position: fixed;
  right: 0;
  top: 0;
  width: 420px;
  height: 100%;
  box-shadow: rgba(5, 13, 54, 0.05) 5px 15px 30px 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  z-index: 9999999;
  padding: 30px;
  transform: translateX(100%);
}
.tpsideinfo {
@media (max-width: 767px) {
    width: 320px;
  }
}
.tpsideinfo.tp-shop-sidebar-opened {
  transform: translateX(0);
}
.tpsideinfo__close {
  color: #fff;
  right: 30px;
  font-size: 18px;
  width: 35px;
  height: 35px;
  position: absolute;
  top: 40px;
}
.tpsideinfo__close {
@media (max-width: 767px) {
    width: 30px;
    height: 30px;
  }
}
.mean-container a.meanmenu-reveal {
  display: none;
}

.mean-container .mean-nav {
  background: none;
  margin-top: 0;
}

.mobile-menu{
  height: calc(100% - 80px);
  overflow-y: auto;
  padding-right: 10px;

  /* width */
&::-webkit-scrollbar {
  width: 5px;
}

/* Track */
&::-webkit-scrollbar-track {
  background: $themecolor;
}

/* Handle */
&::-webkit-scrollbar-thumb {
  background: #232222;
}

}

.mean-container .mean-bar {
  padding: 0;
  min-height: auto;
  background: none;
}

.mean-container .mean-nav ul li {
  position: relative;
  float: left;
  width: 100%;
}

.mean-container .mean-nav > ul {
  padding: 0;
  margin: 0;
  width: 100%;
  list-style-type: none;
  display: block !important;
}

.mean-container a.meanmenu-reveal {
  display: none !important;
}

.mean-container .mean-nav ul li a {
  width: 100%;
  font-size: 14px;
  line-height: 1.5;
  font-weight: 700;
  display: block;
    float: left;
    margin: 0;
    text-align: left;
    text-decoration: none;
    text-transform: uppercase;
    color: #000000;
}
.mean-container .mean-nav ul li a:hover {
  color: $themecolor
}

.mean-container .mean-nav ul li a.mean-expand {
  margin-top: 15px;
  padding: 0 !important;
  line-height: 14px;
  border: none;
  height: 26px;
  width: 100%;
  line-height: 26px;
  color: #fff;
  top: 0;
  font-weight: 400;
  text-align: center;
  position: absolute;
  right: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
    text-transform: uppercase;
}


.mean-container .mean-nav ul li > a > i {
  display: none;
}

.mean-container .mean-nav ul li > a.mean-expand i {
  display: inline-block;
  font-size: 14px;
}

.mean-container .mean-nav > ul > li:first-child > a {
  border-top: 0;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked {
  color: $themecolor;
}

.mean-container .mean-nav ul li a.mean-expand.mean-clicked i {
  transform: rotate(45deg);
}

.profile_image{
      height: 40px;
    margin-right: 5px;
}

.has-dropdown{
  .submenu{
    li{
      margin: 10px 0;
    }
  }
}

.tpsideinfo{
  .mobile-menu-logo{
    @media #{$very-small-device}{
      display: none;
    }
  }
}

.userName {
  
  h5 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0px;
    font-family: 'lexend', "Albert Sans";
  }

  p {
    margin-bottom: 0px;
  }

  @media #{$extra-small-device} {
        margin:  0px 25px;
      }
    }