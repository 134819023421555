// themecolor
$themecolor: #707686;

$primarybtn: #000;

$white: #fff;

$mate-black: #212121;

$black: #707686;

$gradient-one: #2b2b2b;

$gradient-two: #727172;

$footer-back-color:#000;

$header-top-color:#707686;

$breadcrumbs-color:#000;
