@use "./breakpoints" as *;
@use "./mixin" as *;
@use "./color" as *;
@use "./font-size" as *;

.coin_details {
  background-color: #ffffff; /* Light gray background color */
  border-radius: 8px; /* Rounded corners */
  padding: 10px; /* Padding around the content */
  // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow for depth */
  height: fit-content;
  width: fit-content;
  font-size: 18px;
  justify-content: center;
  color: #d4AF37;
  @media #{$large-device} {
    font-size: 14px;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 2px; 
    padding-bottom: 2px;
  }
}
@media #{$large-device} {
  .coin_main {
    width: auto;
    margin-left: auto;
  // margin-top: 10px;
  // margin-left: -70px;
  // height: 35px;
  }
}

.breadcrumb__wrapper {
  margin: 0px 0 10px 0;
  overflow: hidden;

  @media #{$large-device} {
    margin-top: 10px;
    height: 35px;
  }
  .Breadcrumb {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px 0px;
    margin-bottom: 30px;
    background-color: transparent;

    .page-title {
      .title {
        color: $black;
        text-align: center;
        text-transform: uppercase;

        @media #{$large-device} {
          font-size: 18px;
        }
      }
    }
    .breadcrumb__inner {
      .breadcrumb {
        padding: 10px 0;
        background-color: transparent;
        margin-bottom: 0;

        @media #{$large-device} {
          padding: 7px 0;
        }
        .parent__link {
          a {
            color: $themecolor;
            position: relative;
            display: block;
            font-weight: 600;
            @media #{$large-device} {
              font-size: inherit;
            }

            &:hover {
              color: $themecolor;

              &::before {
                left: 0;
                width: 100%;
              }
            }
          }
        }
        .current {
          color: $white;
          font-weight: 600;
          font-size: 16px;
          line-height: 1.5;
          display: flex;
          @media #{$large-device} {
            font-size: inherit;
          }

          &::before {
            margin: 0 15px;
            font-size: 16px;
            content: "/";
            font-family: $font-family-icons;
            color: $white;
            margin-top: 2px;
          }
        }
      }
    }
  }
}
