@use "../common/breakpoints" as *;
@use "../common/mixin" as *;
@use "../common/color" as *;

.badges{
    margin-bottom: 50px;
    .badges_data{
        text-align: center;
        margin-bottom: 2px;
        background-color: #fff;
        border-radius: 10px;

        .inner_image{
            position: relative;
            .dummy_background_color::before {
                content: "\2B22";
                color: rgb(247, 96, 121);
                font-size:135px;
                position: relative;
            }
            .dummy_background::before {
                content: "\2B22";
                color: grey;
                font-size:135px;
            }
            
            img{
                height: 75px;
                object-fit: contain;
                position: absolute;
                right: 0;
                left: 0;
                margin: auto;
                top: 15px;
                bottom: 0;
            }

            .counter_badge{
                position: absolute;
                top: 60%;
                right: 31%;
                margin: auto;
                // background-color: #fff;
                width: 25px;
                height: 25px;
                padding: 2px;
                outline: 'none'
                // border-radius: 50px;
                // border: 1px solid rgb(251, 247, 247);
            }
        }
        .completedText{
            margin-top: -40px;
            margin-bottom: 28px;
            font-size: 10px;
            color: white;
            // font-weight: 800;
            background-color: rgb(247, 96, 121);
            margin-left: 37%;
            margin-right: 37%;
            border-radius: 5px;
            padding: 1.5px;
            // width: 45px;
            
        }
        p{
            color:$mate-black;
            padding-bottom: 20px;
            margin-top: 5px;
            font-weight: 600;
        }
    }

    &.battle_statistics_badges{
        .questions_details_inner{
            display: flex;
            align-items: center;

            @media (max-width: 575px) {
                flex-direction: column;
            }
        }

        .badges_data{
            .inner_image {
                img {
                    height: 30px;
                    top: 5px;
                }
                .dummy_background_color{
                    &::before{
                        font-size: 65px;
                    }
                }
            }
        }
    }
}