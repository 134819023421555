@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

/* Quiz play */

.quizplay {
  display: flex;
  color: $white;

  .morphisam {
    border-radius: 46px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    padding: 30px;
    position: relative;

    @media #{$small-device} {
      padding: 15px;
    }

    &::after {
      position: absolute;
      content: "";
      width: 95%;
      height: 30px;
      bottom: -5px;
      z-index: -1;
      background-color: $mate-black;
      border-radius: 30px;
      left: 0;
      right: 0;
      margin: auto;

      @media #{$small-device} {
        width: 80%;
      }
    }
  }

  .quiz__logo {
    @include flex-center;
    margin: auto;
  }

  .left-sec {
    .bottom__left {
      display: block;

      .cat__Box {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-bottom: 40px;

        .left-line {
          border: 1px solid rgb(179, 179, 179);
          margin: auto;
          display: block;
          width: 50px;
          align-items: center;
          justify-content: center;
          line-height: 2px;
        }

        .quizplay__title {
          margin: auto 10px;
          font-size: 1.25rem;
          @media #{$large-device} {
            font-size: inherit;
          }
        }

        .right-line {
          border: 1px solid rgb(179, 179, 179);
          margin: auto;
          width: 50px;
          @include flex-center;
          line-height: 2px;
        }
      }

      .bottom__cat__box {
        display: flex;

        .inner__Cat__box {
          width: 100%;
          height: 405px;
          overflow-y: auto;
          list-style-type: none;
          text-decoration: none;
          padding-left: 0;
          position: relative;

          @media (max-width: 991px) {
            height: auto;
          }

          @media (max-width: 575px) {
            height: auto;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: $mate-black;
          }

          .button {
            display: flex;
            border-radius: 15px;
            align-items: center;
            position: relative;
            text-align: center;
            margin-bottom: 10px;
            // background-color: #5f5f5f;

            &:hover {
              &::after {
                cursor: pointer;
                transition: all 1s ease-in-out;
                width: 100%;
              }
            }

            .active {
              border: 10px;
            }
            .button.active-one {
              &::after {
                height: 100px !important;
              }
            }
          }

          .Box__icon {
            margin-left: 10px;
            @include flex-center;
            z-index: 1;
            color: $white;

            img {
              width: 30px;
              height: 30px;
              max-width: 100%;
              max-height: 100%;
              border-radius: 5px;
            }
          }

          .Box__text {
            margin-left: 20px;
            text-align: center;
            @include flex-center;
            padding-top: 15px;
            @include flex-center;
            z-index: 1;
          }

          li {
            cursor: pointer;
            margin-right: 10px;
          }
        }
      }
    }
  }

  // subcatslider
  .subcat__slider__context {
    display: block;
    position: relative;

    .inner__icon {
      @include flex-center;
      margin: auto;
      position: absolute;
      content: "";
      width: 100%;
      height: 2px;
      display: inline-block;
      background-color: grey;
    }

    .cat__Box {
      display: flex;
      flex-direction: row;
      position: relative;

      .left-line {
        border: 1px solid rgb(179, 179, 179);
        margin: auto;
        display: block;
        width: 30%;
        align-items: center;
        justify-content: center;
        line-height: 2px;
      }

      .quizplay__title {
        margin: auto 10px;
      }

      .right-line {
        border: 1px solid rgb(179, 179, 179);
        margin: auto;
        @include flex-center;
        width: 30%;
        line-height: 2px;
      }

      .subcat__p {
        font-size: 1.25rem;
        display: block;
        margin: 5px auto;
        justify-content: center;
        text-align: center;
        align-items: center;

        @media #{$large-device} {
          font-size: inherit;
        }
      }
    }

    .quizplay-slider {
      padding: 20px 0px;
      position: relative;

      .subcatintro__sec {
        margin: 30px auto;
        .card {
          height: auto;
          border-radius: 25px;
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
          background-color: #fafafa;
          cursor: pointer;

          .card__name {
            p:first-child {
              font-size: 1rem;
              font-weight: 800;
              padding-bottom: 7px;
            }
            p {
              font-size: 0.825rem;
              font-weight: 500;
              letter-spacing: 1px;
            }
          }

          &::after {
            position: absolute;
            content: "";
            background-color: $mate-black;
            width: 100%;
            height: 57px;
            z-index: -1;
            margin-top: 47px;
            border-radius: 30px;
          }
        }
      }

      .no_sub_data {
        position: absolute;
        top: 0%;
        left: 0;
        right: 0;
        @include flex-center;
      }

      .swiper-button-next {
        right: -20px;
        color: $white;
        background-image: linear-gradient(93deg, $gradient-one 0%, $gradient-two 100%) !important;
        padding: 0px 23px;
        border-radius: 50%;
        opacity: 1;
        cursor: pointer;

        &:hover {
          background-color: $themecolor !important;
          overflow: hidden;

          &::after {
            animation: moveoutright 1s linear;
          }

          @keyframes moveoutright {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(100px);
            }
          }
        }
      }

      .swiper-button-prev {
        left: -20px;
        color: $white;
        background-image: linear-gradient(93deg, $gradient-one 0%, $gradient-two 100%) !important;
        padding: 0px 23px;
        border-radius: 50%;
        opacity: 1;
        cursor: pointer;

        &:hover {
          background-color: $themecolor !important;
          overflow: hidden;

          &::after {
            animation: moveoutleft 1s linear;
          }

          @keyframes moveoutleft {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(-100px);
            }
          }
        }
      }

      .swiper-wrapper {
        // padding: 0 60px;

        @media #{$large-device} {
          margin: auto;
        }
      }
    }

    .sub_cat_title {
      @include flex-center;
    }

    .active-one {
      color: $white;
      background-color: $themecolor !important;

      p {
        color: $white;
      }
    }

    .unactive-one {
      color: $mate-black;
    }
  }

  // level

  .right__bottom {
    &.cat__Box {
      display: flex;
      flex-direction: row;
      position: relative;

      h6 {
        font-size: 1.25rem;

        @media #{$large-device} {
          font-size: inherit;
        }
      }
    }

    .left-line,
    .right-line {
      border: 1px solid rgb(179, 179, 179);
      margin: auto;

      width: 30%;
      @include flex-center;
      line-height: 2px;
      display: block;
      align-items: center;
      justify-content: center;
      line-height: 2px;
    }
  }

  .unlock__levels__card {
    position: relative;
    top: 0;
    left: 0;

    .card {
      height: 85px;
      border-radius: 25px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #fafafa;
      @include flex-center;
      flex-direction: column;
      cursor: pointer;
      position: relative;

      .bubble-aprox {
        right: 100%;
        bottom: 100%;
        width: 100%;
        height: 100%;
        background-color: $primarybtn;
        transition: all 0.3s;
        border-bottom-right-radius: 10%;
        position: absolute;
      }

      &:hover {
        .bubble-aprox {
          transition: all 0.3s;
          right: 0;
          bottom: 0;
        }
      }

      .questions {
        .inner_que {
          padding-left: 5px;
        }
      }

      .level__icon {
        color: $mate-black;

        &.open_lock {
          color: $themecolor;
        }
      }

      &::after {
        position: absolute;
        content: "";
        background-color: $mate-black;
        width: 100%;
        height: 57px;
        z-index: -1;
        margin-top: 33px;
        border-radius: 30px;
      }
    }
  }
}

/* quizzzone */


.Quizzone {
  // margin: 100px 0;
  // height: 100%;

  @media (max-width: 767px) {
    margin: 0px 0px 50px;
  }
  @media (min-width: 767px) {
    .list_item{
      justify-content: center;
      overflow-x: hidden;
    }
  }
  
  .inner__Quizzone {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
  
    @media #{$extra-small-device} {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .card {
      @include flex-center;
      height: auto;
      text-align: center;
      border-radius: 10px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #fafafa;
      position: relative;
      cursor: pointer;

      .card__icon {
        margin-top: -85px;

        img {
          height: 80px;
          background-color: transparent;
        }
      }

      &:hover {
        img {
          -webkit-transition: all 0.25s;
          -moz-transition: all 0.25s;
          -ms-transition: all 0.25s;
          -o-transition: all 0.25s;
          transition: all 1.25s;
          transform: rotateY(360deg);
        }
      }

      .title__card {
        display: block;
        /* padding: 10px 10px; */
        /* margin: 10px 0px; */

        .inner__title {
          color: $black;
          letter-spacing: 1px;
          font-size: 1rem;
          font-weight: 900;
          margin-bottom: 0px;
        }
      }

      &::after {
        position: absolute;
        content: "";
        background-color: $themecolor;
        width: 125px;
        height: 20px;
        z-index: -1;
        border-radius: 30px;
        bottom: -6px;
        display: none;

        @media #{$min-1400-device} {
          width: 180px;
        }
      }
    }

    .card_disabled {
      @include flex-center;
      height: 150px;
      border-radius: 25px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
      background-color: #fafafa;
      text-align: center;
      position: relative;
      cursor: pointer;
      flex-direction: column;

      .card__icon {
        margin-top: -85px;
      }

      .title__card {
        display: block;
        padding: 10px 10px;
        margin-top: 25px;

        .inner__title {
          color: $black;
          text-transform: capitalize;
          letter-spacing: 1px;
          font-size: 1rem;
          font-weight: 900;
        }
      }

      &::after {
        position: absolute;
        content: "";
        background-color: $themecolor;
        width: 125px;
        height: 20px;
        z-index: -1;
        border-radius: 30px;
        bottom: -5px;

        @media #{$min-1400-device} {
          width: 180px;
        }
      }
    }
  }

  ul {
    padding-left: 0;

    li {
      text-decoration: none;
      list-style: none;
      margin: 50px 0;
      @media #{$extra-small-device} {
        margin: 0;
      }
      // &:nth-child(1) {
      //   margin-left: 245px;
      //   @media #{$extra-small-device} {
      //     margin: 0;
      //   }

      //   @media (min-width: 480px) and (max-width: 1199px) {
      //     margin: 50px 0;
      //   }
      // }
      // &:nth-child(3) {
      //   margin-right: 245px;
      //   @media #{$max-width-1199-device} {
      //     margin: auto;
      //   }
      // }

      @media #{$very-small-device} {
        width: 100%;
        margin: auto;
      }
    }
  }

  .small__div {
    @media #{$desktop-device} {
      width: 20%;
    }
  }
}

.custom-icon {
  font-size: 1.5em;
}
.custom-icon strong {
  margin-top: 0.7rem;
  font-size: 0.7em;
}

@media (min-width: 1400px) {
  .custom-col-xxl {
    flex: 0 0 auto;
    width: 13%;
  }
}

.custom_row {
  margin-left: 55px;

  @media #{$custom-max-1399-device} {
    margin-left: 0;
  }
}

.title h5{
  color: #fafafa;
  font-size: 1rem;
}

.searchInput {
  border-radius: 100px;
  /* padding: 10px; */
  width: 100%;
  /* height: 50px; */
}

.search-input-container {
  display: flex;
  align-items: center;
}

.search-input {
  padding: 10px 35px;
  border: 1px solid #ccc;
  border-radius: 100px;
  width: 100%;
}

.search-button {
  padding: 10px;
  background-color: transparent;
  border: none;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  color: #000;
  position: absolute;
}

.rpGame {
  max-width: 100%;
  max-height: 100%;
  border-radius: 17px;
}


.card__icon img {
  /* Add styles for the icon image */
  max-width: 100%;
  height: auto;
  /* Add other styles as needed */
}

.card {
  /* Add styles for the card container */
  border: none;
  /* Add other styles as needed */
}

.submit__answer__btn {
  color: #fff !important;
  background: #6f7787 !important;
  box-shadow: none !important;
  transition: 0.5s ease-in;
  border-color: transparent;
  width: auto !important;

}


.skip__btn{
  color: #fff !important;
  background: #6f7686 !important;
  box-shadow: none !important;
  transition: 0.5s ease-in;
  border-color: transparent;
  // width: auto !important;
}

.lifeline__button{
  color: #fff !important;
  background: #353935 !important;
  box-shadow: none !important;
  transition: 0.5s ease-in;
  border-color: transparent;
}

.buttons-container {
  margin-bottom: 25px; /* Adjust the value as per your preference */
}

// .position-relative {
//   position: relative;
// }

// .tooltip-badge {
//   position: absolute;
//   top: -38px; /* Adjust the value to position the tooltip above the button */
//   left: 85%;
//   transform: translateX(-50%);
//   z-index: 1; /* Ensure the tooltip appears above the button */
// }

// .tooltip-badge .badge {
//   width: 24px; /* Set a fixed width and height for the circle */
//   height: 24px;
//   font-size: 18px;
//   border-radius: 50%;
//   background-color: #353935;
//   color: white; /* Set text color to contrast with the background */
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   position: relative;
// }

// /* Optional: Add some styling to make it look more like a speech bubble */
// .tooltip-badge::before {
//   content: '';
//   position: absolute;
//   top: 70%;
//   left: 5%;
//   margin-left: -9px; /* Adjust as needed */
//   border-width: 11px;
//   border-style: solid;
//   border-color: transparent transparent #353935 transparent; /* Match the badge background color */
// }
.icon-container {
  position: absolute;
bottom: 13px;
  right: 39px;
  transform: translate(50%, -50%);
  display: flex;
  align-items: center;
}

.number {
  position: absolute;
  top: 42%;
  left: 58%;
  transform: translate(-50%, -50%);
  color: blue;
  font-weight: bold;
  font-size: 11px;
  }
  .coinIcon {
     position: absolute;
     top: 23%;
     color: #d4AF37; 
     font-size: 14px; 
     left: 20%;
     color: 'black'; 
     padding-bottom: '5px'; 
     position:'absolute';
  }




.back__button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #161616 !important;
  background: #ffffff !important;
  box-shadow: none !important;
   transition: 0.3s ease-in;
  border-color: transparent;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  border: 2px solid;
}


.profile__Image{
  width: 75px;
  height: 75px;
  margin: auto;
  display: grid;
  gap: 39px;
}

.profile__Image img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.profile__Image img:hover{
  -webkit-transition: all 0.25s;
    -moz-transition: all 0.25s;
    -ms-transition: all 0.25s;
    -o-transition: all 0.25s;
    transition: all 1.25s;
    transform: rotateY(360deg);
}

.quiz__name h6 {

  font-size: 12px;
  font-weight: 500;
  margin: 13px 0px 8px;
  font-family: 'Lexend';
  text-align: center;
}